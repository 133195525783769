/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";

const initialFilterValues = {
  status: ""
};

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onClickOfViewOrderActionButton, onClickOfSendEmailActionButton, data }
) => {
  const renderViewCommentButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-primary btn-sm mx-1"
        onClick={() => {
          onClickOfViewOrderActionButton(row);
        }}
      >
        <i className="fas fa-eye"></i>
      </Button>
    );
  };

  const renderSendMailButton = () => {
    return (
      <Button
        variant="btn btn-icon btn-light-primary btn-sm mx-1"
        onClick={() => {
          onClickOfSendEmailActionButton(row);
        }}
      >
        <i className="fas fa-envelope"></i>
      </Button>
    );
  };

  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-center">
        {renderViewCommentButton()}
        {renderSendMailButton()}
      </ButtonToolbar>
      <> </>
    </>
  );
};
