/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Formik } from "formik";
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { ButtonLoader } from "../../../../utils/ButtonLoader";

const initialFilterValues = {
  status: ""
};

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { onClickOfUserStatusActionButton, data }
) => {
  var { status } = row || {};
  status = status ? 1 : 0;
  const renderUserStatusButton = () => {
    return (
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          values.userId = row.id; 
          onClickOfUserStatusActionButton(values);
        }}
        onReset={(values) => {
          onClickOfUserStatusActionButton(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-center"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              handleReset();
            }}
          >
            <div className="form-group row">
              <select
                className="form-control "
                style={{ width: "30px" }}
                name="status"
                placeholder=""
                onChange={(e) => {
                  setFieldValue("status", e.target.value);
                  handleSubmit();
                }}
                onBlur={handleBlur}
                value={status}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-center">
        {renderUserStatusButton()}
      </ButtonToolbar>
      <> </>
    </>
  );
};
