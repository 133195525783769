import {
  deleteCall,
  getCall,
  getMediaCall,
  getPDFCall,
  patchCall,
  postCall,
  postCallWithMedia
} from "../api";
import {
  accessLogListUrl,
  addProductUrl,
  changePasswordUrl,
  deleteProductUrl,
  editProductUrl,
  editUsersUrl,
  emailOrderUrl,
  emailShippingNumbersUrl,
  exportAccessLogUrl,
  exportProductsUrl,
  forgotPasswordUrl,
  generateOrderLinkUrl,
  importProductsUrl,
  loginUrl,
  logoutUrl,
  orderCommentsUrl,
  ordersUrl,
  productListUrl,
  recentAccessLogPDFUrl,
  recentHistoryPDFUrl,
  recentHistoryUrl,
  resetPasswordUrl,
  sendMailUrl,
  shippingNumbersUrl,
  storeShippingNumbersUrl,
  syncOrderIdUrl,
  uploadDocumentUrl,
  uploadOrderAttachmentUrl,
  uploadProfilePicUrl,
  usersUrl
} from "./apiList";
import {
  addProductObj,
  changePasswordObj,
  editProductObj,
  editUserObj,
  emailOrderObj,
  emailShippingNumbersObj,
  forgotPasswordObj,
  generateOrderLinkObj,
  importProductsObj,
  loginObj,
  orderCommentsObj,
  resetPasswordObj,
  sendMailObj,
  storeShippingNumbersObj,
  uploadDocumentObj,
  uploadProfilePicObj
} from "./apiObjects";

export function loginApi({ email, password }) {
  return postCall(loginUrl(), loginObj({ email, password }));
}

export function uploadProfilePicApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadProfilePicUrl(),
    uploadProfilePicObj({ file }),
    containerkey
  );
}

export function recentHistoryApi() {
  return getCall(recentHistoryUrl());
}

export function productListApi({ queryParams }) {
  return getCall(productListUrl({ queryParams }));
}

export function deleteProductApi({ id }) {
  return deleteCall(deleteProductUrl({ id }));
}

export function accessLogListApi({ queryParams }) {
  return getCall(accessLogListUrl({ queryParams }));
}

export function addProductApi({ materialCode, serialNumber }) {
  return postCall(
    addProductUrl(),
    addProductObj({
      materialCode,
      serialNumber
    })
  );
}

export function editProductApi({
  id,
  materialCode,
  serialNumber,
  is_validate
}) {
  return patchCall(
    editProductUrl(),
    editProductObj({
      id,
      materialCode,
      serialNumber,
      is_validate
    })
  );
}

export function recentHistoryPDFApi({ id, customPdfName }) {
  return getPDFCall(recentHistoryPDFUrl({ id }), customPdfName);
}

export function recentAccessLogPDFApi({ id, customPdfName }) {
  return getPDFCall(recentAccessLogPDFUrl({ id }), customPdfName);
}

export function recentHistoryPrintApi({ id }) {
  return getCall(recentHistoryPDFUrl({ id }));
}

export function logoutApi({ userId }) {
  return deleteCall(logoutUrl({ userId }));
}

export function exportProductsApi({ queryParams, filename, extention }) {
  return getPDFCall(exportProductsUrl({ queryParams }), filename, extention);
}

export function exportAccessLogApi({ queryParams, filename, extention }) {
  return getPDFCall(exportAccessLogUrl({ queryParams }), filename, extention);
}

export function uploadDocumentApi(files) {
  return postCallWithMedia(uploadDocumentUrl(), uploadDocumentObj(files));
}

export function importProductsApi({ file }) {
  return postCall(importProductsUrl(), importProductsObj({ file }));
}

export function syncOrderIdApi({ orderId }) {
  return getCall(syncOrderIdUrl({ orderId }));
}

export function uploadOrderAttachmentApi({ orderId, container, file }) {
  return postCallWithMedia(uploadOrderAttachmentUrl({ orderId }), {
    container,
    file
  });
}

export function generateOrderLinkApi({ orderId, id, media }) {
  return postCall(
    generateOrderLinkUrl({ orderId }),
    generateOrderLinkObj({ id, media })
  );
}

export function orderCommentsApi({ order_id, comment }) {
  return postCall(orderCommentsUrl(), orderCommentsObj({ order_id, comment }));
}

export function emailOrderApi({ order_id, to, url }) {
  return postCall(emailOrderUrl(), emailOrderObj({ order_id, to, url }));
}

export function downloadVideoAudoApi({ mediaUrl, file_name }) {
  return getMediaCall(mediaUrl, file_name);
}

export function getShippingNumberApi({ orderId }) {
  return getCall(shippingNumbersUrl({ orderId }));
}

export function storeShippingNumber({
  order_id,
  product_sku,
  shipping_number
}) {
  return postCall(
    storeShippingNumbersUrl(),
    storeShippingNumbersObj({ order_id, product_sku, shipping_number })
  );
}

export function emailShippingNumber({
  order_id,
  product_sku,
  shipping_number
}) {
  return postCall(
    emailShippingNumbersUrl(),
    emailShippingNumbersObj({ order_id, product_sku, shipping_number })
  );
}

export function usersApi({ queryParams }) {
  return getCall(usersUrl({ queryParams }));
}

export function editUsersApi({ userId, status }) {
  return patchCall(editUsersUrl({ userId }), editUserObj({ status }));
}

export function ordersApi({ queryParams }) {
  return getCall(ordersUrl({ queryParams }));
}

export function sendMail({ mailTo, id }) {
  return postCall(sendMailUrl(id), sendMailObj({ mailTo }));
}

export function changePasswordApi({ old_password, password }) {
  return postCall(
    changePasswordUrl(),
    changePasswordObj({
      old_password,
      password
    })
  );
}

export function forgotPasswordApi(mail) {
  return postCall(forgotPasswordUrl(), forgotPasswordObj(mail));
}

export function resetPasswordApi({ new_password, code , id}) {
  return postCall(resetPasswordUrl(), resetPasswordObj({ new_password, code, id }));
}
