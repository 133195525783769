import moment from "moment";
import { SERVER_URL } from "../../app/ServerConfig";
import { YYYY_MM_DD } from "../../app/utils/const";

export function loginUrl() {
  let url = "/users/login";
  return url;
}

export function uploadProfilePicUrl() {
  let url = "/uploads/upload";
  return url;
}

export function recentHistoryUrl() {
  let url = "/transactions/getRecentHistory";
  return url;
}

export function productListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getProduct?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function accessLogListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/getaccessLog?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function getImageBasedOnNameUrl(imageName) {
  let url = `${SERVER_URL}/downloads/download?folder_name=profilepic&image=${imageName}&id=${localStorage.getItem(
    "auth-token"
  )}`;
  return url;
}

export function getPdfBasedOnNameUrl({ file, folder }) {
  let url = `/downloads/download?folder_name=${
    folder ? folder : ""
  }&image=${file}&id=${localStorage.getItem("auth-token")}`;
  return url;
}

export function recentHistoryPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function recentAccessLogPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function addProductUrl() {
  let url = `/addProduct`;
  return url;
}

export function editProductUrl() {
  let url = `/editProduct`;
  return url;
}

export function logoutUrl({ userId }) {
  let url = `/users/logout/${userId}`;
  return url;
}

export function deleteProductUrl({ id }) {
  let url = `/removeProduct/${id}`;
  return url;
}

export function exportProductsUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, is_validate, date } = filter || {};
  let skip = (pageNumber - 1) * pageSize;

  let url = `/exportCSV?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }

  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }

  if (is_validate) {
    url = url.concat(`&isValidate=${is_validate}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  return url;
}

export function exportAccessLogUrl({ queryParams }) {
  const { pageNumber, pageSize, filter, sortField, sortOrder } =
    queryParams || {};
  const { material_code, serial_number, response_code, is_validate, date } =
    filter || {};
  let skip = (pageNumber - 1) * pageSize;
  let url = `/exportlogs?limit=${pageSize}&offset=${skip}`;

  if (material_code) {
    url = url.concat(`&materialCode=${material_code}`);
  }
  if (serial_number) {
    url = url.concat(`&serialNumber=${serial_number}`);
  }
  if (response_code) {
    url = url.concat(`&responseCode=${response_code}`);
  }

  if (date && date.datefrom) {
    url = url.concat(`&datefrom=${date.datefrom}`);
  }

  if (date && date.dateto) {
    url = url.concat(`&dateto=${date.dateto}`);
  }

  if (sortField && sortOrder) {
    url = url.concat(`&order=${sortField} ${sortOrder.toUpperCase()}`);
  }
  return url;
}

export function uploadDocumentUrl() {
  let url = `/upload`;
  return url;
}

export function importProductsUrl() {
  let url = `/importProducts`;
  return url;
}

export function syncOrderIdUrl({ orderId }) {
  let url = `/orders/validate/${orderId}`;
  return url;
}

export function downloadOrderAttachmentUrl({ fme_upload_files_name }) {
  let url = `https://charmbykaalo.com/wp-content/uploads/${fme_upload_files_name}`;
  return url;
}

export function uploadOrderAttachmentUrl({ orderId }) {
  let url = `/orders/upload/${orderId}`;
  return url;
}

export function generateOrderLinkUrl({ orderId }) {
  let url = `/orders/generatelink/${orderId}`;
  return url;
}

export function emailOrderUrl() {
  let url = `/orders/mail`;
  return url;
}

export function orderCommentsUrl() {
  let url = `/orders/storeComment`;
  return url;
}

export function shippingNumbersUrl({ orderId }) {
  let url = `/orders/shippingNumber/${orderId}`;
  return url;
}

export function storeShippingNumbersUrl() {
  let url = `/orders/shippingNumber`;
  return url;
}

export function emailShippingNumbersUrl() {
  let url = `/orders/shippingMail`;
  return url;
}

export function usersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const skip = (pageNumber - 1) * pageSize;
  let url = `/users/usersList?perPage=${pageSize}&offset=${pageNumber}`;
  const { last_name, first_name, email, status } = filter || {};

  // Below code is for search
  if (last_name) {
    url = url.concat(`&filter[last_name]=${last_name}`);
  }
  if (first_name) {
    url = url.concat(`&filter[first_name]=${first_name}`);
  }
  if (email) {
    url = url.concat(`&filter[email]=${email}`);
  }

  if (status) {
    url = url.concat(`&filter[status]=${status}`);
  }

  // Below code is for Sorting Id and Date
  if (sortField && sortOrder) {
    url = url.concat(`&filter[orderBy]=${sortField} ${sortOrder}`);
  }
  return url;
}

export function editUsersUrl({ userId }) {
  let url = `/users/${userId}`;
  return url;
}

export function ordersUrl({ queryParams }) {
  const { pageSize, pageNumber, filter, sortField, sortOrder } =
    queryParams || {};
  const skip = (pageNumber - 1) * pageSize;
  let url = `/orders/ordersList?perPage=${pageSize}&page=${pageNumber}`;
  const { id, sku, user_id, email, created_at } = filter || {};

  // Below code is for search
  if (id) {
    url = url.concat(`&filter[id]=${id}`);
  }
  if (sku) {
    url = url.concat(`&filter[sku]=${sku}`);
  }
  if (email) {
    url = url.concat(`&filter[email]=${email}`);
  }
  if (user_id) {
    url = url.concat(`&filter[user_id]=${user_id}`);
  }

  // Below code is for Sorting Id and Date
  if (sortField && sortOrder) {
    url = url.concat(`&filter[orderBy]=${sortField} ${sortOrder}`);
  }
  return url;
}

export function sendMailUrl(id) {
  let url = `/orders/sendMail/${id}`;
  return url;
}

export function changePasswordUrl() {
  let url = `/users/changePassword`;
  return url;
}

export function forgotPasswordUrl() {
  let url = "/users/forgotPassword";
  return url;
}

export function resetPasswordUrl() {
  let url = "/users/resetPassword";
  return url;
}
