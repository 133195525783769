import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"; //, checkIsActive
import SuccessModal from "../../Modal/SuccessModal";
import { resetPasswordApi } from "../../../../axios/services/services";
import ErrorModal from "../../Modal/ErrorModal";
import { ForgotPasswordSchema } from "../../../utils/FormikSchema";

const initialValues = {
  password: "",
  confirmpassword: "",
  otp: ""
};

function ResetPassword(props) {
  const { id, verificationCode } = props.match.params;
  const [isRequested, setIsRequested] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    formik.setFieldValue("otp", verificationCode);
  }, []);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      let resetPasswordObj = {
        new_password: values.password,
        code: values.otp,
        id: id
      };
      const resetPasswordResponse = await resetPasswordApi(resetPasswordObj);
      disableLoading();
      if (resetPasswordResponse.responseCode != 200) {
        setErrorMessage(resetPasswordResponse.responseMessage);
        handleErrorModalShow();
        return;
      }
      handleConfirmShow();
    }
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-left mb-5">
            <h5 className="text-dark font-weight-boldest fs-16 mb-0">
              Reset Your Password
            </h5>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            autoComplete="off"
          >
            <div className="form-group mb-4 fv-plugins-icon-container">
              <input
                type="number"
                className={`form-control h-auto py-4 py-md-5 px-6 ${getInputClasses(
                  "text"
                )}`}
                placeholder="Verificaton code"
                name="otp"
                {...formik.getFieldProps("otp")}
              />
              {formik.touched.otp && formik.errors.otp ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.otp}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group mb-4 fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control h-auto py-4 py-md-5 px-6 ${getInputClasses(
                  "text"
                )}`}
                placeholder="New Password"
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group mb-4 fv-plugins-icon-container">
              <input
                type="password"
                className={`form-control h-auto py-4 py-md-5 px-6 ${getInputClasses(
                  "text"
                )}`}
                placeholder="Re-Enter Password"
                name="confirmpassword"
                {...formik.getFieldProps("confirmpassword")}
              />
              {formik.touched.confirmpassword &&
              formik.errors.confirmpassword ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.confirmpassword}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-block">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-brand font-weight-bold px-9 py-4 my-3 w-100"
                disabled={loading}
              >
                Reset Password
                {loading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </form>
        </div>
      )}
      <SuccessModal
        message={"You have successfully reset your password!"}
        isConfirmModal={isConfirmModal}
        handleConfirmHide={handleConfirmHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        buttonLabel={"Go to Login"}
        onClick={() => {
          props.history.push("/auth/login");
        }}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}
export default injectIntl(connect(null, auth.actions)(ResetPassword));
