import { useFormik } from "formik";
import React, { useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import { ChangePasswordSchema } from "../../utils/FormikSchema";
import { initialFilter } from "../orders/OrdersUIHelpers";
import PasswordValidationDropdownNewPassword from "../../utils/PasswordValidationDropdownNewPassword";
import PasswordValidationDropdownOldPassword from "../../utils/PasswordValidationDropdownOldPassword";
import PasswordValicationDropdownConfirmPassword from "../../utils/PasswordValicationDropdownConfirmPassword";
import { changePasswordApi } from "../../../axios/services/services";
import { isUnauthorized } from "../../utils/utils";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";

export function ChangePasswordPage() {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [loadingChangePassword, setLoadingChangePassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [resetAccountForm, setResetAccountForm] = useState(0);

  const chanePasswordUIEvents = {};
  const initialValuesChangePassword = {
    oldpassword: "",
    newpassword: "",
    confirmpassword: ""
  };

  const enableLoadingChangePassword = () => {
    setLoadingChangePassword(true);
  };

  const disableLoadingChangePassword = () => {
    setLoadingChangePassword(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const formikChangePassword = useFormik({
    initialValues: initialValuesChangePassword,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoadingChangePassword();
      if (values.confirmpassword != values.newpassword) {
        setErrorMessage("New Password is not matched with Confirmed Password");
      }
      let changePasswordObj = {
        old_password: values.oldpassword,
        password: values.newpassword
      };
      const changePasswordResponse = await changePasswordApi(changePasswordObj);
      //
      if (changePasswordResponse.responseCode != 200) {
        isUnauthorized({ response: changePasswordResponse });
        setErrorMessage(changePasswordResponse.responseMessage);
        handleErrorModalShow();
        disableLoadingChangePassword();
        return;
      }
      resetForm();
      console.log(changePasswordResponse);
      setSuccessMessage("Password Changed Successfully");
      handleSuccessModalShow();
      disableLoadingChangePassword();
    },
    onReset: (values, { resetForm }) => {
      setResetAccountForm(Math.random());
    }
  });

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Change Password</span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div>
            <form
              onSubmit={formikChangePassword.handleSubmit}
              onReset={formikChangePassword.handleReset}
              className="form fv-plugins-bootstrap fv-plugins-framework"
              autoComplete="off"
            >
              <Row className="form-group mt-5">
                <Col
                  sm={12}
                  md={10}
                  lg={8}
                  xl={6}
                  className="offset-md-1 offset-lg-2 offset-xl-3"
                >
                  <Form.Row>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Control
                        name="oldpassword"
                        type="password"
                        placeholder="Current Password*"
                        size="lg"
                        {...formikChangePassword.getFieldProps("oldpassword")}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Control
                        name="newpassword"
                        type="password"
                        placeholder="New Password*"
                        size="lg"
                        {...formikChangePassword.getFieldProps("newpassword")}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="12" className="mb-3">
                      <Form.Control
                        name="confirmpassword"
                        type="password"
                        placeholder="Re-Enter New Password*"
                        size="lg"
                        {...formikChangePassword.getFieldProps(
                          "confirmpassword"
                        )}
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md="12">
                      <Button
                        variant="success"
                        type="submit"
                        className="mr-2"
                        disabled={loadingChangePassword}
                      >
                        Change Password
                        {loadingChangePassword && (
                          <span className=" mr-3 ml-3 spinner spinner-white"></span>
                        )}
                      </Button>
                      <Button variant="secondary" type="reset">
                        Cancel
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Col>
              </Row>
            </form>
          </div>
        </Card.Body>
      </Card>

      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}
