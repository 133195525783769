import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar, Card, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { sendMailSchema } from "../../utils/FormikSchema";
import ErrorModal from "../Modal/ErrorModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ButtonLoader } from "../../utils/ButtonLoader";

function SendMailModal({
  isUpdateSendMailModal,
  handleUpdateSendMailModalHide,
  data,
  onClickOfCancelButton,
  onClickofSubmitButton,
  isLoadingForSendMailSave
}) {
  const init = {
    products: []
  };
  const { email, id } = data || {};
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [initialValues, setInitialValues] = useState(init);

  useEffect(() => {
    const initS = initialValues;
    // for (let i = 0; i < line_items.length; i++) {
    //   const oneProduct = line_items[i];
    //   initS.products.push({
    //     shipping_number: "",
    //     product_sku: oneProduct.sku,
    //     name: oneProduct.name
    //   });
    // }

    setInitialValues(initS);
  }, [data]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <>
      <Modal
        show={isUpdateSendMailModal}
        onHide={handleUpdateSendMailModalHide}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            Send Mail
          </h3>
        </Modal.Header>
        <Modal.Body className="text-center pb-0">
          <Formik
            initialValues={initialValues}
            validationSchema={sendMailSchema}
            enableReinitialize
            onSubmit={(values) => {}}
          >
            {({
              touched,
              errors,
              values,
              resetForm,
              handleSubmit,
              setFieldValue
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Modal.Body>
                  <div className="form-group mt-5">
                    <Field>
                      {(a) => (
                        <div>
                          <Card className="card-custom card-stretch make-payment-wrapper">
                            <Card.Body>
                              <div className="row">
                                <div className="col-md-12 d-flex align-items-baseline">
                                  <div className="col-md-6">
                                    <input
                                      placeholder="Email"
                                      autoComplete="off"
                                      type="email"
                                      className="form-control h-auto py-5 px-6 mb-2"
                                      name="email"
                                      value={email}
                                      onChange={(event) => {
                                        const { target } = event || {};
                                        const { value } = target || {};
                                        setFieldValue(`email`, value);
                                      }}
                                    />
                                    {/* {console.log(touched)}
                                    {touched && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          <ErrorMessage id={errors.email} />
                                        </div>
                                      </div>
                                    )} */}
                                  </div>

                                  <div className="col-md-6 d-flex">
                                    <Button
                                      variant="btn-lg btn-light-primary mt-5 mb-5 px-10"
                                      disabled={isLoadingForSendMailSave}
                                      onClick={(e) => {
                                        if (values.email.trim().length == 0) {
                                          setErrorMessage("Email is required");
                                          setIsErrorModalOpen(true);
                                          return;
                                        }
                                        onClickofSubmitButton(id, values.email);
                                      }}
                                    >
                                      {isLoadingForSendMailSave ? (
                                        <ButtonLoader />
                                      ) : (
                                        "Send Email"
                                      )}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </Field>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="btn-lg btn-light-primary mt-5 mb-5 px-10"
            onClick={onClickOfCancelButton}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
export default SendMailModal;
