import React from "react";
export const StatusColumnFormatter = (row) => {
  console.log(row);
  let statusName = "";

  if (row == 1) {
    statusName = "Active";
  } else {
    statusName = "Inactive";
  }
  return (
    <>
      <span className="text-dark-75 d-block"></span>
      <> {statusName || "-"} </>
    </>
  );
};
