export function checkUserExistOrNotObj({
  cellnumber,
  email,
  username,
  social_signin
}) {
  let obj = {
    cellnumber,
    email,
    username,
    social_signin
  };
  return obj;
}

export function signUpObj({
  id,
  firstname,
  lastname,
  email,
  cellnumber,
  password,
  username,
  business_name,
  address_1,
  address_2,
  countryId,
  stateId,
  cityId,
  zip_code,
  role_id,
  profile,
  social_signin,
  bankId,
  bank_nickname,
  is_aba_disclosure_signed,
  bank_routing_number,
  bank_account_number,
  account_type,
  activeStep
}) {
  let obj = {
    id,
    firstname,
    lastname,
    email,
    cellnumber,
    password,
    username,
    business_name,
    address_1,
    address_2,
    countryId,
    stateId,
    cityId,
    zip_code,
    role_id,
    profile,
    social_signin,
    bankId,
    bank_nickname,
    is_aba_disclosure_signed,
    bank_routing_number,
    bank_account_number,
    account_type,
    activeStep
  };
  return obj;
}

export function verifyOTPObj({ userId, otp_email, otp_mobile }) {
  let obj = {
    userId,
    otp_email,
    otp_mobile
  };
  return obj;
}

export function loginObj({ email, password }) {
  let obj = {
    email,
    password
  };
  return obj;
}

export function uploadProfilePicObj({ file }) {
  let obj = {
    file
  };
  return obj;
}

export function editUserDetailObj({
  id,
  email,
  cellnumber,
  firstname,
  lastname,
  username,
  account_number,
  address_1,
  address_2,
  countryId,
  stateId,
  cityId,
  zip_code
}) {
  let obj = {
    id,
    email,
    cellnumber,
    firstname,
    lastname,
    username,
    account_number,
    address_1,
    address_2,
    countryId,
    stateId,
    cityId,
    zip_code
  };
  return obj;
}

export function editProfilePicObj({ id, profile, cellnumber }) {
  let obj = {
    id,
    profile,
    cellnumber
  };
  return obj;
}

export function uploadAutoAcceptFundObj({ is_autoaccept }) {
  let obj = {
    is_autoaccept
  };
  return obj;
}

export function blockContactObj({
  block_reason,
  added_contact_id,
  is_block_contact,
  is_report_contact,
  id
}) {
  let obj = {
    block_reason,
    added_contact_id,
    is_block_contact,
    is_report_contact,
    id
  };
  return obj;
}

export function sendChatObj({ userId, receiver_id, text, created_by }) {
  let obj = {
    userId,
    receiver_id,
    text,
    created_by
  };
  return obj;
}

export function resendOTPObj({ userId, email, cellnumber }) {
  let obj = {
    userId,
    email,
    cellnumber
  };
  return obj;
}

export function resendtwofactorOtpObj({ userId, email, cellnumber }) {
  let obj = {
    userId,
    email,
    cellnumber
  };
  return obj;
}

export function resendEmailOTPObj({ userId, email }) {
  let obj = {
    userId,
    email
  };
  return obj;
}

export function resendCellNumberOTPObj({ userId, cellnumber }) {
  let obj = {
    userId,
    cellnumber
  };
  return obj;
}

export function sendReminderObj({ id }) {
  let obj = {
    id
  };
  return obj;
}

export function addAsContactObj({ userId, added_contact_id }) {
  let obj = {
    userId,
    added_contact_id
  };
  return obj;
}

export function checkUserIsAddedAsContactObj({ added_contact_id }) {
  let obj = {
    added_contact_id
  };
  return obj;
}

export function makeAPaymentObj({
  amount,
  cellnumber,
  senderId,
  receiverId,
  transactionTypeId,
  transactionStatusId,
  transactionModeId,
  transaction_number,
  sc_transactionId,
  address_1,
  address_2,
  city,
  state_province,
  country_region,
  zip_code,
  sc_type,
  fundingAccountId,
  front_cheque,
  back_cheque,
  other,
  created_by
}) {
  let obj = {
    amount,
    cellnumber,
    senderId,
    receiverId,
    transactionTypeId,
    transactionStatusId,
    transactionModeId,
    transaction_number,
    sc_transactionId,
    address_1,
    address_2,
    city,
    state_province,
    country_region,
    zip_code,
    sc_type,
    fundingAccountId,
    front_cheque,
    back_cheque,
    other,
    created_by
  };
  return obj;
}

export function requestAPaymentObj({
  amount,
  cellnumber,
  senderId,
  receiverId,
  transactionTypeId,
  transactionStatusId,
  transactionModeId,
  transaction_number,
  sc_transactionId,
  address_1,
  address_2,
  city_name,
  state_name,
  country_name,
  zip_code,
  sc_type,
  other,
  created_by
}) {
  let obj = {
    amount,
    cellnumber,
    senderId,
    receiverId,
    transactionTypeId,
    transactionStatusId,
    transactionModeId,
    transaction_number,
    sc_transactionId,
    address_1,
    address_2,
    city_name,
    state_name,
    country_name,
    zip_code,
    sc_type,
    other,
    created_by
  };
  return obj;
}

export function acceptFundObj({
  isaccept,
  transactionId,
  notificationreceiverId,
  accept_reject_note,
  fundingAccountId
}) {
  let obj = {
    isaccept,
    transactionId,
    notificationreceiverId,
    accept_reject_note,
    fundingAccountId
  };
  return obj;
}

export function addNewBankObj({
  userId,
  bankId,
  bank_nickname,
  account_type,
  bank_routing_number,
  bank_account_number
}) {
  let obj = {
    userId,
    bankId,
    bank_nickname,
    account_type,
    bank_routing_number,
    bank_account_number
  };
  return obj;
}

export function editBankObj({ userId, bank_nickname }) {
  let obj = {
    userId,
    bank_nickname
  };
  return obj;
}

export function twoFactorAuthObj({ cellnumber, userId }) {
  let obj = {
    cellnumber,
    userId
  };
  return obj;
}

export function transferToBankObj({ amount, bankId }) {
  let obj = {
    amount,
    bankId
  };
  return obj;
}

export function twoFactorVerificationObj({ twofactorauth_otp }) {
  let obj = {
    twofactorauth_otp
  };
  return obj;
}

export function confirmBankObj({ id, amount_one, amount_two }) {
  let obj = {
    id,
    amount_one,
    amount_two
  };
  return obj;
}

export function uploadFrontChequeObj({ file }) {
  let obj = {
    file
  };
  return obj;
}

export function loginTwoFactorVerificationObj({
  twofactorauth_otp,
  remember_me,
  userId
}) {
  let obj = {
    twofactorauth_otp,
    remember_me,
    userId
  };
  return obj;
}

export function firebaseDeviceTokenObj({ devicetoken }) {
  let obj = {
    devicetoken
  };
  return obj;
}

export function sendTransactionDetailsSCObj({
  txId,
  payer,
  payee,
  amount,
  location,
  date,
  propertyAdress,
  paymentStatus,
  paymentType,
  paymentDeatils,
  paymentId
}) {
  let obj = {
    txId,
    payer,
    payee,
    amount,
    location,
    date,
    propertyAdress,
    paymentStatus,
    paymentType,
    paymentDeatils,
    paymentId
  };
  return obj;
}

export function addCardObj({
  cardnumber,
  // cvv_number,
  nameonthecard,
  expirydate,
  cardstatus,
  account_type,
  cardtype
}) {
  let obj = {
    cardnumber,
    // cvv_number,
    nameonthecard,
    expirydate,
    cardstatus,
    account_type,
    cardtype
  };
  return obj;
}

export function editCardObj({ cardnickname }) {
  let obj = {
    cardnickname
  };
  return obj;
}

export function supportObj({
  firstname,
  lastname,
  email,
  mobilenumber,
  supporttype,
  prefferedmethod,
  description
}) {
  let obj = {
    firstname,
    lastname,
    email,
    mobilenumber,
    supporttype,
    prefferedmethod,
    description
  };
  return obj;
}

export function primaryBankConfirmObj({ bankId }) {
  let obj = {
    bankId
  };
  return obj;
}

export function getCommissionBasedOnAmountObj({
  amount,
  transactionModeId,
  userType,
  is_sc
}) {
  let obj = {
    amount,
    transactionModeId,
    userType,
    is_sc
  };
  return obj;
}

export function generateLinkTokenObj({ userId }) {
  let obj = {
    userId
  };
  return obj;
}

export function publicTokenObj({ userId, publicToken }) {
  let obj = {
    userId,
    publicToken
  };
  return obj;
}

export function deleteBankObj({ userId, bankId }) {
  let obj = {
    userId,
    bankId
  };
  return obj;
}

export function addProductObj({ materialCode, serialNumber }) {
  let obj = {
    materialCode,
    serialNumber
  };
  return obj;
}

export function editProductObj({
  id,
  materialCode,
  serialNumber,
  is_validate
}) {
  let obj = {
    id,
    materialCode,
    serialNumber,
    is_validate
  };
  return obj;
}

export function uploadDocumentObj({ file, containername }) {
  let obj = {
    file,
    containername
  };
  return obj;
}

export function importProductsObj({ file }) {
  let obj = {
    file
  };
  return obj;
}

export function emailOrderObj({ order_id, to, url }) {
  let obj = {
    order_id,
    to,
    url
  };
  return obj;
}

export function generateOrderLinkObj({ id, media }) {
  let obj = {
    id,
    media
  };
  return obj;
}

export function orderCommentsObj({ order_id, comment }) {
  let obj = {
    order_id,
    comment
  };
  return obj;
}

export function storeShippingNumbersObj({
  order_id,
  product_sku,
  shipping_number
}) {
  let obj = {
    order_id,
    product_sku,
    shipping_number
  };
  return obj;
}

export function emailShippingNumbersObj({
  order_id,
  product_sku,
  shipping_number
}) {
  let obj = {
    order_id,
    product_sku,
    shipping_number
  };
  return obj;
}

export function editUserObj({ status }) {
  let obj = {
    status
  };
  return obj;
}

export function sendMailObj({ mailTo }) {
  let obj = {
    mailTo
  };
  return obj;
}

export function changePasswordObj({ old_password, password }) {
  let obj = {
    old_password,
    password
  };
  return obj;
}

export function forgotPasswordObj(mail) {
  let obj = mail;
  return obj;
}

export function resetPasswordObj({ new_password, code, id }) {
  let obj = {
    new_password,
    code,
    id
  };
  return obj;
}
