import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../OrdersUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useOrdersUIContext } from "../OrdersUIContext";

export function OrdersTable({ isLoading, ordersList, ordersTotalCount, data }) {
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      ids: ordersUIContext.ids,
      setIds: ordersUIContext.setIds,
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams,
      onClickOfViewOrderActionButton:
        ordersUIContext.onClickOfViewOrderActionButton,
      onClickOfSendEmailActionButton:
        ordersUIContext.onClickOfSendEmailActionButton
    };
  }, [ordersUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "Order ID",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "sku",
      text: "Product SKU",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.BuyersEmailColumnFormatter
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.DateTimeColumnFormatter
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      sortCaret: sortCaret,
      classes: "pr-md-0 text-center notresponsive",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        onClickOfViewOrderActionButton:
          ordersUIProps.onClickOfViewOrderActionButton,
        onClickOfSendEmailActionButton:
          ordersUIProps.onClickOfSendEmailActionButton,
        data
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: ordersTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: ordersUIProps.queryParams.pageSize,
    page: ordersUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      {/* <p>Hello</p> */}
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={ordersList ? ordersList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  ordersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              {/* <NoRecordsFoundMessage
                list={ordersList}
                title={"No Orders Found"}
                description={
                  "There is no orders found, please search with other criteria."
                }
              /> */}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
