export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "25", value: 25 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {},
  sortOrder: "desc", // asc||desc
  sortField: "created_at",
  pageNumber: 1,
  pageSize: 10,
};
