const publicIp = require("public-ip");
let ipv4 = "";
export function isUnauthorized({ response }) {
  if (response && response.Error && response.Error.statusCode === 401) {
    removeUserDetailsFromLocalStorage();
  }
}

export function removeUserDetailsFromLocalStorage() {
  localStorage.removeItem("auth-token");
  localStorage.removeItem("userInstance");
  window.location.reload();
}

export function isKeyExistInObject(Object, Key) {
  return Key in Object;
}

export function isObjectEmpty(object) {
  if (object === null) {
    return true;
  }
  return Object.keys(object).length === 0;
}

export function getPublicIP() {
  return new Promise(async (resolve) => {
    if (ipv4 !== "") {
      resolve(ipv4);
      return;
    }
    ipv4 = await publicIp.v4();
    resolve(ipv4);
  });
}

export function normalizeInput(value, previousValue) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
}

export const phoneNumberMask = [
  "(",
  /[1-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export const monthYearMask = [/\d/, /\d/, "/", /\d/, /\d/];

export const getStatusClass = (name) => {
  let statusClass = "";
  if (name === "Awaiting Processing" || name === "AwaitingProcessing") {
    statusClass = "status-pending";
  } else if (name === "Pending") {
    statusClass = "status-pending";
  } else if (name === "Being Processed" || name === "BeingProcessed") {
    statusClass = "status-in-progress";
  } else if (name === "Accepted") {
    statusClass = "status-success";
  } else if (name === "Completed") {
    statusClass = "status-success";
  } else if (name === "Approved") {
    statusClass = "status-success";
  } else if (name === "Cancelled") {
    statusClass = "status-refund";
  } else if (name === "Refunded") {
    statusClass = "status-refund";
  } else if (name === "Processing Return" || name === "ProcessingReturn") {
    statusClass = "status-refund";
  } else if (name === "Returned Collected" || name === "ReturnedCollected") {
    statusClass = "status-success";
  } else if (
    name === "Returned Not Collected" ||
    name === "ReturnedNotCollected"
  ) {
    statusClass = "status-failed";
  } else if (name === "Rejected") {
    statusClass = "status-rejected";
  } else if (name === "Declined") {
    statusClass = "status-rejected";
  } else if (name === "Failed") {
    statusClass = "status-failed";
  } else if (name === "Hold") {
    statusClass = "status-onhold";
  } else if (
    name === "Awaiting Authorization" ||
    name === "AwaitingAuthorization"
  ) {
    statusClass = "status-in-progress";
  } else if (name === "Authorized") {
    statusClass = "status-in-progress";
  } else if (name === "Archived") {
    statusClass = "status-success";
  } else if (name === "Returned / Refunded" || name === "Returned/Refunded") {
    statusClass = "status-refund";
  } else if (
    name === "Authorization Expired" ||
    name === "AuthorizationExpired"
  ) {
    statusClass = "status-failed";
  } else if (name === "Being Settled" || name === "BeingSettled") {
    statusClass = "status-in-progress";
  } else if (name === "Settled") {
    statusClass = "status-success";
  } else if (name === "Returned Ineligible" || name === "ReturnedIneligible") {
    statusClass = "status-failed";
  } else if (name === "Vantiv Completed" || name === "VantivCompleted") {
    statusClass = "status-success";
  } else if (name === "Refund Processing" || name === "RefundProcessing") {
    statusClass = "status-in-progress";
  }
  return statusClass;
};

export const getStatusClassForIsValidate = (is_validate) => {
  let statusClass = "";
  if (is_validate === false) {
    statusClass = "status-pending";
  } else if (is_validate === true) {
    statusClass = "status-success";
  } else {
    statusClass = "status-pending";
  }
  return statusClass;
};

export const getStatusNameForIsValidate = (is_validate) => {
  let status = "";
  if (is_validate === false) {
    status = "Not Validated";
  } else if (is_validate === true) {
    status = "Validated";
  } else {
    status = "Not Validated";
  }
  return status;
};

export const minLength = (value, minValue) => {
  const bool = minValue > value.length;
  return bool;
};

export const maxLength = (value, maxValue) => {
  const bool = value.length > maxValue;
  return bool;
};
