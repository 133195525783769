import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useOrdersUIContext } from "../OrdersUIContext";

const initialFilterValues = {
  email: "",
  first_name: "",
  last_name: "",
  status: ""
};

export function OrdersFilter({}) {
  const ordersUIContext = useOrdersUIContext();
  const ordersUIProps = useMemo(() => {
    return {
      queryParams: ordersUIContext.queryParams,
      setQueryParams: ordersUIContext.setQueryParams
    };
  }, [ordersUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { id, sku, email } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.id = id !== "" ? id : undefined;
    filter.sku = sku !== "" ? sku : undefined;
    filter.email = email !== "" ? email : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(ordersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, ordersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      ordersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              handleReset();
            }}
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="id"
                  placeholder="Search ID"
                  onBlur={handleBlur}
                  value={values.id}
                  onChange={(e) => {
                    setFieldValue("id", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> ID</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="sku"
                  placeholder="Search SKU"
                  onBlur={handleBlur}
                  value={values.sku}
                  onChange={(e) => {
                    setFieldValue("sku", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> SKU</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="email"
                  placeholder="Search Email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> Email</b>
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
