import React, { useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import DatePicker from "react-datepicker";
import { dd_MM_yyyy, YYYY_MM_DD_T_HH_MM_SS } from "../../../utils/const";
import moment from "moment";
import { useUsersUIContext } from "../UsersUIContext";

const initialFilterValues = {
  email: "",
  first_name: "",
  last_name: "",
  status: ""
};

export function UsersFilter({}) {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams
    };
  }, [usersUIContext]);

  const prepareFilter = (queryParams, values) => {
    const { first_name, last_name, email, status } = values;

    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.first_name = first_name !== "" ? first_name : undefined;
    filter.last_name = last_name !== "" ? last_name : undefined;
    filter.email = email !== "" ? email : undefined;
    filter.status = status !== "" ? status : undefined;

    newQueryParams.filter = filter;
    return newQueryParams;
  };

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(usersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, usersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      usersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialFilterValues}
        onSubmit={(values) => {
          applyFilter(values);
        }}
        onReset={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          resetForm,
          handleReset
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
            onReset={() => {
              resetForm(initialFilterValues);
              handleReset();
            }}
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="email"
                  placeholder="Search Email"
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> Email</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="first_name"
                  placeholder="Search First Name"
                  onBlur={handleBlur}
                  value={values.first_name}
                  onChange={(e) => {
                    setFieldValue("first_name", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> First Name</b>
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="last_name"
                  placeholder="Search Last Name"
                  onBlur={handleBlur}
                  value={values.last_name}
                  onChange={(e) => {
                    setFieldValue("last_name", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  Search by<b> Last Name</b>
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <small className="form-text text-muted">
                  Filter by<b> Status</b>
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
