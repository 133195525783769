import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "../_metronic/layout";
import { AccessLogPage } from "./modules/AccessLog/AccessLogPage";
import { ChangePasswordPage } from "./modules/changepassword/changePasswordPage";
import { HistoryPage } from "./modules/History/HistoryPage";
import { OrdersPage } from "./modules/orders/ordersPage";
import { UsersPage } from "./modules/users/usersPage";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>        
        <Redirect exact from="/auth/login" to="/dashboard" />
        <Route path="/dashboard" component={OrdersPage} />
        <Route path="/orders" component={OrdersPage} />
        <Route path="/users" component={UsersPage} />
        <Route path="/changePassword" component={ChangePasswordPage} />
      </Switch>
    </Suspense>
  );
}
