import * as Yup from "yup";

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required Field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .required("Required Field")
});

export const UploadOrderAttachmentSchema = Yup.object().shape({
  media: Yup.string().required("Required Field")
});

export const AddShippingNumberchema = Yup.object().shape({
  products: Yup.array().of(
    Yup.object().shape({
      shipping_number: Yup.string().required("Required Field")
    })
  )
});

export const sendMailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(500, "Email must not be more than 500 characters")
    .required("Required Field")
});

export const ChangePasswordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .required("Required field"),
  newpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      "Passwords must have least uppercase letter, lowercase letter, number and symbol"
    )
    .required("Required field"),
  confirmpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .when("newpassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newpassword")],
        "New password and Confirm password didn't match"
      ),
    })
    .required("Required field"),
});

export const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Passwords must have least uppercase letter, lowercase letter, number and symbol"
    )
    .required("Required Field"),
  confirmpassword: Yup.string()
    .required("Required Field")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
    }),
  otp: Yup.string()
    .matches(/^[0-9]*$/, "Must be only digits")
    .required("Required Field")
});
