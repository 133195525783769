import React from "react";
import { Button, Card } from "react-bootstrap";
import { UsersFilter } from "./users-filter/UsersFilter";
import { UsersTable } from "./users-table/UsersTable";

export function UsersCard({ isLoading, usersList, usersTotalCount }) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Users</span>
          </h3>
          <div>
           
          </div>
        </Card.Header>
        <Card.Body>
          <div>
            <UsersFilter />
            <UsersTable
              isLoading={isLoading}
              usersList={usersList}
              usersTotalCount={usersTotalCount}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
