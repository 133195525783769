import React from "react";
import { Button, Card } from "react-bootstrap";
import { OrdersFilter } from "./orders-filter/OrdersFilter";
import { OrdersTable } from "./orders-table/OrdersTable";

export function OrdersCard({ isLoading, ordersList, ordersTotalCount }) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4  d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Orders</span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div>
            <OrdersFilter />
            <OrdersTable
              isLoading={isLoading}
              ordersList={ordersList}
              ordersTotalCount={ordersTotalCount}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
