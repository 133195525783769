import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { ordersApi, sendMail } from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { isUnauthorized } from "../../utils/utils";
import ErrorModal from "../Modal/ErrorModal";
import { OrdersCard } from "./OrdersCard";
import { OrdersUIProvider } from "./OrdersUIContext";
import { initialFilter } from "./OrdersUIHelpers";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import SendMailModal from "./send-mail-modal";
import OrdersViewModal from "./view-order-modal";

export function OrdersPage({ order }) {
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [ordersTotalCount, setOrdersTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [updateOrdersList, setUpdateOrdersList] = useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isUpdateSendMailModal, setUpdateSendMailModal] = useState(false);
  const [isLoadingForSendMailSave, setLoadingForSendMailSave] = useState(false);
  const [isOrdersViewModal, setOrdersViewModal] = useState(false);

  async function getOrdersList() {
    enableLoading();
    const ordersResponse = await ordersApi({ queryParams });
    disableLoading();
    const { Error, responseData } = ordersResponse || {};
    if (Error) {
      isUnauthorized({ response: ordersResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setOrdersList(responseData.list);
    setOrdersTotalCount(responseData.totalRecords);
  }

  useEffect(() => {
    getOrdersList();
  }, [updateOrdersList, queryParams]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoadingSendMailSave = () => {
    setLoadingForSendMailSave(true);
  };

  const disableLoadingSendMailSave = () => {
    setLoadingForSendMailSave(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };
  const handleUpdateSendMailModalShow = () => {
    setUpdateSendMailModal(true);
  };

  const handleUpdateSendMailModalHide = () => {
    setUpdateSendMailModal(false);
  };

  const onClickOfCancelSendMailButton = () => {
    handleUpdateSendMailModalHide();
  };

  const handleOrdersViewModalShow = () => {
    setOrdersViewModal(true);
  };

  const handleOrdersViewModalHide = () => {
    setOrdersViewModal(false);
  };

  const onClickOfSaveSendMailButton = async (id, email) => {
    enableLoadingSendMailSave();
    let sendMailObj = await sendMail({
      mailTo: email,
      id: id
    });
    const { Error: ErrorSendMail, message: SendMailErrorMessage } =
      sendMailObj || {};
    if (ErrorSendMail) {
      isUnauthorized({ response: SendMailErrorMessage });
      setErrorMessage(ErrorSendMail.message);
      handleErrorModalShow();
      disableLoadingSendMailSave();
      return;
    }
    handleSuccessModalShow();
    disableLoadingSendMailSave();
    handleUpdateSendMailModalHide();
    setSuccessMessage("Mail Sent Successfully.");
  };

  const ordersUIEvents = {
    onClickOfSendEmailActionButton: async (row) => {
      setData(row);
      handleUpdateSendMailModalShow();
    },
    onClickOfViewOrderActionButton: async (row) => {
      setData(row);
      handleOrdersViewModalShow();
    }
  };

  return (
    <>
      <OrdersUIProvider
        ordersUIEvents={ordersUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        {isOrdersViewModal && (
          <OrdersViewModal
            isOrdersViewModal={isOrdersViewModal}
            handleOrdersViewModalHide={handleOrdersViewModalHide}
            data={data}
            onClickOfCancelButton={handleOrdersViewModalHide}
          />
        )}
        {isUpdateSendMailModal && (
          <SendMailModal
            isUpdateSendMailModal={isUpdateSendMailModal}
            handleUpdateSendMailModalHide={handleUpdateSendMailModalHide}
            data={data}
            onClickOfCancelButton={onClickOfCancelSendMailButton}
            onClickofSubmitButton={onClickOfSaveSendMailButton}
            isLoadingForSendMailSave={isLoadingForSendMailSave}
          />
        )}

        <OrdersCard
          isLoading={isLoading}
          ordersList={ordersList}
          ordersTotalCount={ordersTotalCount}
        />
      </OrdersUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
