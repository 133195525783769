import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { editUsersApi, usersApi } from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { isUnauthorized } from "../../utils/utils";
import ErrorModal from "../Modal/ErrorModal";
import { UsersCard } from "./UsersCard";
import { UsersUIProvider } from "./UsersUIContext";
import { initialFilter } from "./UsersUIHelpers";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";

export function UsersPage({ user }) {
  const [data, setData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [usersTotalCount, setUsersTotalCount] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [updateUsersList, setUpdateUsersList] = useState(0);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  async function getUsersList() {
    enableLoading();
    const usersResponse = await usersApi({ queryParams });
    disableLoading();
    const { Error, responseData } = usersResponse || {};
    if (Error) {
      isUnauthorized({ response: usersResponse });
      setErrorMessage(Error.message);
      handleErrorModalShow();
      return;
    }
    setUsersList(responseData.list);
    setUsersTotalCount(responseData.totalRecords);
  }

  useEffect(() => {
    getUsersList();
  }, [updateUsersList, queryParams]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const usersUIEvents = {
    onClickOfUserStatusActionButton: async (row) => {
      setData(row);
      const { status, userId } = row || {};
      let updateUserObj = {
        status: parseInt(status),
        userId: userId
      };

      const usersResponse = await editUsersApi(updateUserObj);
      if (usersResponse.responseCode != 200) {
        setErrorMessage(usersResponse.responseMessage);
        handleErrorModalShow();
        return;
      }
      setSuccessMessage("User Updated Successfully.");
      handleSuccessModalShow();
      setUpdateUsersList(Math.random());
    }
  };

  return (
    <>
      <UsersUIProvider
        usersUIEvents={usersUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        <UsersCard
          isLoading={isLoading}
          usersList={usersList}
          usersTotalCount={usersTotalCount}
        />
      </UsersUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
