import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import * as uiHelpers from "../UsersUIHelpers";
import {
  getHandlerTableChange,
  PleaseWaitMessage,
  sortCaret
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useUsersUIContext } from "../UsersUIContext";

export function UsersTable({ isLoading, usersList, usersTotalCount, data }) {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      setIds: usersUIContext.setIds,
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
      onClickOfUserStatusActionButton:
      usersUIContext.onClickOfUserStatusActionButton
    };
  }, [usersUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "last_name",
      text: "Last name",
      sort: true,
      sortCaret: sortCaret
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      sortCaret: sortCaret,
      classes: "pr-md-0 text-center notresponsive",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        onClickOfUserStatusActionButton:
          usersUIProps.onClickOfUserStatusActionButton,
        data
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: usersTotalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: usersUIProps.queryParams.pageSize,
    page: usersUIProps.queryParams.pageNumber,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      {/* <p>Hello</p> */}
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={usersList ? usersList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  usersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <PleaseWaitMessage isLoading={isLoading} />
              {/* <NoRecordsFoundMessage
                list={usersList}
                title={"No Orders Found"}
                description={
                  "There is no orders found, please search with other criteria."
                }
              /> */}
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
