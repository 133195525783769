import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { forgotPasswordApi } from "../../../../axios/services/services";
import ErrorModal from "../../Modal/ErrorModal";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  FORGOT_PASSWPRD_DESC,
  FORGOT_PASSWPRD_PLACE_HOLDER,
} from "../../../utils/const";

const initialValues = {
  emailOrPhone: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    emailOrPhone: Yup.string()
      .min(8, "Email/Phone number must be at least 8 characters")
      .max(132, "Email/Phone number must not be more than 132 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let cellnumberoremail = "";
      const { emailOrPhone } = values;
      const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const regexPhone = /^\d{10}$/;
      if (!emailOrPhone.match(regexEmail) && emailOrPhone.match(regexPhone)) {
        cellnumberoremail = emailOrPhone.replace(
          /(\d{3})(\d{3})(\d{4})/,
          "($1) $2-$3"
        );
      } else if (emailOrPhone.match(regexEmail)) {
        cellnumberoremail = emailOrPhone;
      } else {
        setErrorMessage("Email must be valid");
        handleErrorModalShow();
        return;
      }
      enableLoading();
      setSubmitting(true);
      let forgotPasswordObj = {
        email : cellnumberoremail,
      };
      const forgotPasswordResponse = await forgotPasswordApi(forgotPasswordObj);
      disableLoading();
      setSubmitting(false);
      if (forgotPasswordResponse.responseCode != 200) {
        setErrorMessage(forgotPasswordResponse.responseMessage);
        handleErrorModalShow();
        return;
      }
      const url = `/auth/reset-password/${forgotPasswordResponse.responseData.id}/${forgotPasswordResponse.responseData.token}`;
      props.history.push(url);
    },
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-left mb-10">
            <h3 className="font-size-h1">
              <img
                src={toAbsoluteUrl("/media/logos/PayXtreme.png")}
                height="70"
                alt=""
              />
            </h3>
            <div className="text-secondory h6">{FORGOT_PASSWPRD_DESC}</div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            autoComplete="off"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="text"
                placeholder={FORGOT_PASSWPRD_PLACE_HOLDER}
                className={`form-control-lg form-control h-auto py-4 py-md-5 px-6${getInputClasses(
                  "email"
                )}`}
                name="emailOrPhone"
                {...formik.getFieldProps("emailOrPhone")}
              />
              {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.emailOrPhone}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-start">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-brand font-weight-bold px-9 py-4 my-3"
                disabled={loading}
              >
                Submit
                {loading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-brand font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
